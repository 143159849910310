import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PostCard = ({ data }) => {
  let slug = data.frontmatter.slug
  slug = slug.toString().startsWith("/") ? slug : "/" + slug

  return (
    <article className="post-card">
      {data.frontmatter.featuredImage ? (
        <Link to={slug}>
          <Img
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + " - Featured image"}
            className="featured-image"
          />
        </Link>
      ) : (
        ""
      )}
      <div className="post-content">
        <h2 className="title">
          <Link to={slug}>{data.frontmatter.title}</Link>
        </h2>
        <p className="meta">
          <time>{data.frontmatter.date}</time>
        </p>
      </div>
    </article>
  )
}
export default PostCard

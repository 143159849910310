import React from "react"
import { graphql, Link } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"
import Particles from "react-particles-js"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import SEO from "../components/seo"
import { particlesConfig } from "./index-page-particles.config"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 480
              maxHeight: 380
              quality: 80
              srcSetBreakpoints: [960, 1440]
            ) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO />
      <Particles className="home-particles" params={particlesConfig} />
      <div className="home-banner grids">
        <div></div>
        <div>
          <h1 className="title">{frontmatter.title}</h1>
          <p className="tagline">{frontmatter.tagline}</p>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Link to={frontmatter.cta.ctaLink} className="button">
            {frontmatter.cta.ctaText}
            <span className="icon -right">
              <RiArrowRightSLine />
            </span>
          </Link>
        </div>
        <div></div>
      </div>
      <BlogListHome />
    </Layout>
  )
}

export default HomePage
